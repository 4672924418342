$color-blue: #6ee9ff;
$color-light-blue: #DBEEDE; // Knowit mint
$color-light-blue-2: #DBEEDE; // Knowit mint
$color-purple: #333333; // Knowit digital black
$color-yellow: #B7DEBD; // Knowit pear
$color-red: #FAC0B1; // Knowit flamingo
$color-green: #43f8b6;

$color-bg: #f9f9fa;
$color-border: #DBEEDE; // Knowit mint

$color-grey-50: #f7f7f7;
$color-grey-100: #e1e1e6;
$color-grey-200: #c6c6cc;
$color-grey-300: #adadb3;
$color-grey-400: #939399;
$color-grey-500: #797980;
$color-grey-600: #5e5e66;
$color-grey-700: #43434d;
$color-grey-800: #242433;
$color-grey-900: #0e0e1a;

$color-link: #0075eb;
