
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        
































































































































































































@import '@/styles/_colors.scss';

.kpis {
  display: grid;
  grid-gap: span(0, 1);
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  margin-top: 1.5rem;

  @media screen and (min-width: bp(l)) {
    grid-gap: span(0, 1, span(10));
    width: span(7, 0, span(10));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(6, 0, span(10));
  }
}

.empty {
  @media screen and (min-width: bp(l)) {
    grid-gap: span(0, 1, span(10));
    width: span(7, 0, span(10));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(6, 0, span(10));
  }
}

.form-row {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));

  & > .form-group {
    margin: 0;
  }
}

.kpi {
  max-width: 30rem;
  padding: 1rem;
  background: white;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba($color-grey-400, 0.3);

  &--error {
    box-shadow: 0 0 2px 3px rgba($color-red, 0.4);
  }
}

.kpi__validation {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-grey-100;
}

.kpi__valid {
  padding: 0.5rem;
  background: $color-green;
  border-radius: 2px;
}

.kpi__loading {
  padding: 0.5rem;
  border-radius: 2px;
}

.kpi__error {
  padding: 0.5rem;
  background: $color-red;
  border-radius: 2px;
}
